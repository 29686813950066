import "./main.css";

import mapboxgl from "mapbox-gl";

const bounds: { [dataset: string]: mapboxgl.LngLatBoundsLike } = {
  huachuca: [
    [-110.3470046, 31.6076166],
    [-110.314883, 31.6598483]
  ],
  williams: [
    [-122.1372995, 39.1437629],
    [-122.1212202, 39.1657476]
  ]
};

const dataset = window.location.pathname.slice(1);

const tilesEndpoint =
  process.env.TILES_ENDPOINT || "https://data3f2ab74b.z5.web.core.windows.net/";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZ3JhaGFtZ2liYm9ucyIsImEiOiJja3Qxb3Q5bXQwMHB2MnBwZzVyNzgyMnZ6In0.4qLjlbLm6ASuJ5v5gN6FHQ";

const map = new mapboxgl.Map({
  container: "map",
  maxZoom: 20,
  style: {
    version: 8,
    sources: {
      terrain: {
        type: "raster-dem",
        url: "mapbox://mapbox.mapbox-terrain-dem-v1",
        tileSize: 512,
        maxzoom: 14
      },
      base: {
        type: "raster",
        tiles: ["https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"],
        tileSize: 256
      },
      tiles: {
        type: "raster",
        tiles: [`${tilesEndpoint}${dataset}/{z}/{x}/{y}.png`],
        tileSize: 128,
        maxzoom: 20
      }
    },
    terrain: {
      source: "terrain"
    },
    layers: [
      {
        id: "base",
        type: "raster",
        source: "base",
        paint: {
          "raster-saturation": -1,
          "raster-contrast": -0.5
        }
      },
      {
        id: "tiles",
        type: "raster",
        source: "tiles"
      }
    ]
  }
});

map.on("style.load", () => {
  if (bounds[dataset]) map.fitBounds(bounds[dataset], { duration: 0 });
});

let info: Element | undefined;
map.on("mousemove", ({ lngLat: { lng, lat } }) => {
  if (!info) {
    info = document.createElement("div");
    info.id = "info";
    document.body.appendChild(info);
  }
  const elevation = map.queryTerrainElevation([lng, lat]);
  info.textContent = `${lat.toFixed(5)}, ${lng.toFixed(5)} ${elevation?.toFixed(
    0
  )}m`;
});
